import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FirstHeading from "../textcomponents/FirstHeading";
import FormButton from "../textcomponents/FormButton";
import img1 from "../../assets/images/about_back.webp";
import img2 from "../../assets/images/admission_portal.jpg";
import img3 from "../../assets/images/programs_back.webp";

const CardSlider = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      const data = [
        {
          id: 1,
          title: "Card 1",
          description: "This is the description for card 1.",
          img: img1,
        },
        {
          id: 2,
          title: "Card 2",
          description: "This is the description for card 2.",
          img: img2,
        },
        {
          id: 3,
          title: "Card 3",
          description: "This is the description for card 3.",
          img: img3,
        },
        {
          id: 4,
          title: "Card 4",
          description: "This is the description for card 4.",
          img: img1,
        },
        {
          id: 5,
          title: "Card 5",
          description: "This is the description for card 5.",
          img: img2,
        },
        {
          id: 6,
          title: "Card 6",
          description: "This is the description for card 6.",
          img: img3,
        },
      ];
      setCards(data);
    };
    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="ms-0 ms-lg-4">
        <FirstHeading
          count="07"
          title="our blogs"
          tagline="Let’s uncover the hidden stories that shape our lives."
        />
      </div>
      <Slider {...settings}>
        {cards.map((card) => (
          <div className="row g-0">
            <div className="card w-100 col-md-4 col-12" key={card.id}>
              <img
                src={card.img}
                className="card-img-top"
                alt={`Card ${card.id}`}
              />
              <div className="card-body">
                <h5 className="slick_h3">{card.title}</h5>
                <p className="card-text">{card.description}</p>
                <FormButton name="Read More +" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardSlider;
