import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const OurPrograms = () => {
  const accordionData = [
    {
      question: "What is the Master of Billions Administration (MBA)?",
      answer:
        "The Master of Billions Administration (MBA) is an elite program designed to transform exceptional individuals into global leaders capable of commanding billion-dollar enterprises with ownership.",
    },
    {
      question: "Who is eligible to apply for the MBA program?",
      answer:
        "Eligibility is not determined by conventional academic or professional backgrounds. We seek individuals with an unyielding drive for success, an insatiable hunger for power, and the financial ability to put their stake in the company that they will lead.",
    },
    {
      question: "What makes this program different from a traditional MBA?",
      answer:
        "Traditional MBAs teach you how to manage businesses with a mindset of jobs; the Master of Billions Administration trains you to create, command, and dominate it. Our curriculum is designed to challenge conventional wisdom and push you beyond the limits of typical business education.",
    },
    {
      question: "How is the curriculum structured?",
      answer:
        "The curriculum is divided into 30 sections called The Billionaires Suites, each bearing the name of a global titan. Every time you enter a suite, which are not just lessons—they start your transformative process to lead with unparalleled authority right away.",
    },
    {
      question: "What is the Syndicated Curriculum?",
      answer:
        "The Syndicated Curriculum aligns each suite with the legacy of a string of billionaire, drawing on the covert strategies that led them to amass and control immense wealth. It is an intricate web of knowledge designed to elevate your strategic thinking and execution.",
    },
    {
      question: "Is there a practical component to the MBA program?",
      answer:
        "Yes. The program is deeply immersive, thrusting you into real-world scenarios where you must prove your ability to generate and manage vast wealth. Success in these challenges directly impacts your standing within the program.",
    },
    {
      question: "What kind of support can I expect during the program?",
      answer:
        "You will be supported by a network of mentors and advisors—and best of all, the billionaires themselves. These experts would navigate you through the actual treacherous waters of billion-dollar enterprises.",
    },
    {
      question: "What happens after I complete the program?",
      answer:
        "Graduates of the Master of Billions Administration was never intended nor is the purpose of this institution. It was designed for the finish. As you graduate, you are handed over a billion dollar company, and that is enough in itself.",
    },
    {
      question: "How does the program measure success?",
      answer:
        "Success is measured not by grades or exams but by your ability to create and sustain value on a massive scale. The ultimate test is your capability to lead a company to significant profitability, with the potential for equity and real-world rewards.",
    },
    {
      question: "How do I apply for the Master of Billions Administration?",
      answer:
        "Applications are open, but restricted to online only. Prospective candidates must fill out the attached forms and pay a processing fee of USD 65.00 on the link provided on the page of School of Admissions.",
    },
  ];
  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Master of Billions Administration (MBA) ©
            </h1>
            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
              In a world saturated with traditional MBAs, the Master of Billions
              Administration (MBA) stands as the apex of financial and strategic
              education—a program designed not just to teach, but to transform.
              This is no ordinary degree; it is an initiation into the inner
              sanctum of global power, where the stakes are measured in
              billions, and the rewards are unparalleled.
              <br />
              <br />
              The curriculum is an enigma, a labyrinth of knowledge that
              challenges conventional wisdom and reshapes your understanding of
              what it means to lead. We have no time for the mediocrity of case
              studies or theoretical exercises. Instead, you will be thrust into
              a crucible where real-world stakes are the only measure of
              success. Here, you will not simply learn how to create wealth—you
              will be trained to command it, to mold it, to wield it like a
              weapon in the relentless pursuit of market domination.
              <br />
              <br />
              Each module within the Master of Billions Administration is a key,
              unlocking the secrets of the world’s most powerful empires. Our
              Syndicated Curriculum aligns each suite with the legacy of a
              global titan, drawing lessons not from their successes alone but
              from the hidden strategies that propelled them to the zenith of
              power.
              <br />
              <br />
              This program is not for everyone. It is reserved for the few, the
              elite, those who are prepared to forsake the ordinary in pursuit
              of the extraordinary. The Master of Billions Administration is
              your gateway to the highest echelons of influence—a world where
              your decisions will echo across continents and your legacy will be
              etched in the annals of history.
              <br />
              <br />
              For further queries and admission, please click on School of
              Admissions.
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPrograms;
