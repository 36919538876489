import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const EnvironmentProtectionInstitute = () => {
  const accordionData = [
    {
      question:
        "What is the Institute of Environment Protection & Conservation?",
      answer:
        "The Institute of Environment Protection & Conservation is the ultimate authority in environmental stewardship, operating as the new kingpins in the fight to protect and sustain our planet’s vital ecosystems. We are a strategic cartel dedicated to dominating the field of conservation and environmental management.",
    },
    {
      question:
        "What makes this institute different from other environmental organizations?",
      answer:
        "Unlike traditional environmental organizations, we operate with the precision, influence, and power of a cartel. Our approach is uncompromising and strategic, focusing on systematic control over the planet’s resources and the implementation of enduring conservation practices.",
    },
    {
      question:
        "Who should join the Institute of Environment Protection & Conservation?",
      answer:
        "We seek individuals who are not just passionate about the environment, but are also ready to take decisive, powerful action. Our members are visionaries, strategists, and leaders who want to be at the forefront of global environmental protection.",
    },
    {
      question: "What can I expect to learn at the Institute?",
      answer:
        "You will learn advanced strategies in environmental management, conservation tactics, and the art of creating sustainable ecosystems. Our curriculum is designed to transform you into a leader who can command influence and implement large-scale environmental initiatives.",
    },
    {
      question:
        "How does the Institute exert its influence in environmental conservation?",
      answer:
        "We operate through a powerful network of alliances with scientists, policy-makers, and activists. Our influence extends across borders, industries, and governments, allowing us to control and protect key resources and enforce sustainable practices globally.",
    },
    {
      question: "What is the admission process for the Institute?",
      answer:
        "Admission to the Institute is highly selective. We seek individuals with a proven commitment to environmental protection, strategic thinking, and the potential to become influential leaders in the field of conservation.",
    },
    {
      question: "How long is the program?",
      answer:
        "The duration of our programs varies, ranging from intensive short courses to comprehensive year-long programs. Each is designed to equip you with the knowledge and skills needed to take a leading role in global environmental protection.",
    },
    {
      question: "What kind of post-program support can I expect?",
      answer:
        "Graduates of the Institute become part of our powerful network, gaining access to ongoing mentorship, strategic partnerships, and opportunities to lead significant environmental projects and initiatives worldwide.",
    },
    {
      question: "How does the Institute ensure real-world impact?",
      answer:
        "We focus on actionable strategies and real-world applications. Through fieldwork, partnerships, and strategic interventions, our programs are designed to achieve tangible results in environmental conservation and sustainability.",
    },
    {
      question:
        "What is the ultimate goal of the Institute of Environment Protection & Conservation?",
      answer:
        "Our ultimate goal is to dominate the field of environmental protection, ensuring the long-term sustainability of the planet’s ecosystems. We aim to establish a new global order where the health of our environment is maintained through strategic control, influence, and unwavering commitment to conservation.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              INSTITUTE OF ENVIRONMENT <br/> PROTECTION & CONSERVATION
            </h1>

            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              The New Kingpins of the Environment Business
            </h2>

            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $950
              <br />
              <strong>Duration:</strong> 1 day
              <br />
              <strong>Credit Hours:</strong> 2
              <br />
              <strong>Certification:</strong> Institute of Environment
              Protection & Conservation
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Institute of Environment Protection & Conservation,
              where we have redefined what it means to safeguard the planet. We
              are not just protectors; we are the new kingpins of environmental
              stewardship, the ultimate cartel dedicated to preserving and
              conserving the Earth’s resources with an ironclad resolve.
              <br />
              <br />
              In a world where environmental degradation runs rampant, we stand
              as the vanguard—unyielding, strategic, and unapologetically
              powerful. Our mission is simple: to control, protect, and sustain
              the planet’s vital ecosystems with the same precision and
              dominance that only the most formidable cartels possess.
              <br />
              <br />
              We do not negotiate with the forces of destruction; we eliminate
              them. Our approach is systematic, our methods uncompromising, and
              our results undeniable. The Institute of Environment Protection &
              Conservation operates with a singular purpose: to become the
              undisputed authority in environmental management.
              <br />
              <br />
              We are the architects of a new world order, where the health of
              our planet is non-negotiable, and our influence extends across
              borders, industries, and governments. Through our network of
              allies—scientists, policy-makers, and activists—we exert control
              over the key resources and strategies necessary for true
              conservation.
              <br />
              <br />
              Our cartel is not bound by traditional rules. We rewrite them. We
              deploy our collective expertise to protect the Earth’s most
              critical environments, ensuring that every move is calculated,
              every action is effective, and every outcome benefits the global
              ecosystem.
              <br />
              <br />
              We leverage the power of strategic alliances to disrupt the forces
              of environmental degradation, replacing them with sustainable
              practices that are as enduring as they are impactful.
              <br />
              <br />
              Join us, and you will become part of the ultimate syndicate—a
              force that will stop at nothing to preserve the planet for future
              generations. The Institute of Environment Protection &
              Conservation is not just an institution; it is the nerve center of
              a global movement, a cartel with a cause, and the new
              standard-bearer in the fight to protect and conserve our world.
              <br />
              <br />
              Together, we are the enforcers of environmental integrity, the
              guardians of nature’s bounty, and the architects of a sustainable
              future. This is not just conservation—it’s domination. And we are
              the ones in control.
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentProtectionInstitute;
