import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage1.jpg";
import FormButton from "../../components/textcomponents/FormButton";

const InstituteOfOpportunity = () => {
  const accordionData = [
    {
      question: "What is the Institute of Opportunity?",
      answer:
        "The Institute of Opportunity is not just an educational institution—it’s an exclusive brotherhood, a cult of success where power and billions are the only metrics. Here, the institute has already identified projects with propulsion towards becoming billion dollar organizations. Moreover, the Institute has also developed a robust system to take newer ideas and their conversion to billion dollar mark.",
    },
    {
      question: "Who is the Ultra Godfather mentioned in the description?",
      answer:
        "The Ultra Godfather is the visionary founder and overseer of the Institute, whose rules and regulations set the highest standards, transforming ordinary individuals into corporate giants poised to command empires.",
    },
    {
      question:
        "What is the primary objective of the Institute of Opportunity?",
      answer:
        "The primary objective is to forge and incinerate the ordinary, transforming individuals into titans of industry. Here, ambition isn't just encouraged—it’s demanded. The goal is total business dominance and the creation of billion-dollar empires.",
    },
    {
      question: "Who should apply to the Institute of Opportunity?",
      answer:
        "The Institute is for those who are not content with being just another name. It is for individuals who are ready to seize opportunities others cannot even fathom and who possess the vision and grit to align with a cartel of like-minded titans.",
    },
    {
      question:
        "How does the Institute of Opportunity differ from traditional business schools?",
      answer:
        "Unlike traditional business schools, the Institute of Opportunity is not a place of learning—it’s the heart of a movement. It operates with a cartel mentality where only the most ambitious and driven individuals thrive. The focus here is on action, dominance, and the relentless pursuit of success.",
    },
    {
      question: "What can I expect to achieve at the Institute of Opportunity?",
      answer:
        "At the Institute of Opportunity, you will not only build a billion-dollar empire but also disrupt industries and leave an indelible mark on the world. This is where billionaires are born, and the cult of success is shaped.",
    },
    {
      question: "Is the Institute of Opportunity open to everyone?",
      answer:
        "No, the Institute of Opportunity is highly selective. It’s an invitation to those who have demonstrated extraordinary potential and are ready to transcend the ordinary. Only those with the drive to become titans of industry will be considered.",
    },
    {
      question: "What kind of support does the Institute of Opportunity offer?",
      answer:
        "The Institute offers a powerful network of like-minded individuals and mentors who share your vision and ambition. However, success here depends on your ability to align with the movement and push forward relentlessly.",
    },
    {
      question: "How do I join the Institute of Opportunity?",
      answer:
        "Admission to the Institute of Opportunity is by invitation only. Prospective candidates must undergo a rigorous selection process, where their potential to dominate and disrupt industries is thoroughly evaluated.",
    },
    {
      question: "What makes the Institute of Opportunity unique?",
      answer:
        "The Institute of Opportunity is unique because it doesn’t just open doors—it constructs entire corridors of opportunities. Here, success is not just a destination but a way of life, and only those who are ready to embrace this philosophy will thrive.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div class="row">
          <div class="col-12">
            <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              INSTITUTE OF OPPORTUNITY
            </h1>
            <h2 class="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              Beyond Talent & Money
            </h2>

            <p class="feature_homepage_p pt-4">
              <div class="p-3">
                <img
                  src={IMG}
                  alt="Institute of Opportunity"
                  class="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $1,185
              <br />
              <strong>Duration:</strong> 3 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> Institute of Opportunity
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Institute of Opportunity. This is your
              invitation—your one and only call to a cult of success, where the
              currency is power and the only metric is billions. The Institute
              of Opportunity is not merely an institution; it’s an exclusive
              brotherhood where the driven and the daring converge to transcend
              the ordinary.
              <br /> <br />
              This is where the 550 millionaires of the world come to cross the
              threshold into the realm of billionaires. All under the watchful
              eye of The Ultra Godfather, whose rules and regulations set you
              apart. If you think you're just another name—prepare to be the
              next titan, poised to command an empire. Here, we don't recruit
              followers; we forge and incinerate the ordinary, transforming them
              into corporate giants.
              <br /> <br />
              This Institute is not a place of learning; it’s the heart of a
              movement. Understand this: it’s a cartel mentality, a movement
              where ambition isn’t encouraged—it’s demanded. Here, the only path
              is forward, and the only acceptable outcome is total business
              dominance. This is where you align with those who share your
              vision and your grit, those who are ready to seize what others
              cannot even fathom.
              <br /> <br />
              This is your moment. Your opportunity to become part of something
              bigger, stronger, and infinitely more powerful. Welcome to The
              Institute of Opportunity—where billionaires are born, and the cult
              of success is shaped.
              <br /> <br />
              Whether you aim to build a billion-dollar empire, disrupt
              industries, or leave an indelible mark on the world, the Institute
              of Opportunity is your Launchpad. Here, we don’t just open
              doors—we construct entire corridors of opportunities. Join us, and
              embark on a path where success is not just a destination but a way
              of life.
            </p>

            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr class="mt-3 mb-3" />
            <h1 class="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div class="col-12 mt-4">
              <Accordion items={accordionData} />
              <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteOfOpportunity;
