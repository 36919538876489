import React from "react";
import { Link } from "react-router-dom";

const BackgroundSlideshow = () => {
  return (
    <>
      <div className="background_slideshow">
        <div className="w-100">
          <div className="hero_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_h1">INSTITUTE  OF</h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_h1_2">BILLIONAIRES</h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
              <h1 className="text-white text-uppercase pt-2 m-0 BackgroundSlideshow_h1_3 pe-3">“Become a Billionaire. No Plan B!”</h1>
            </div>
          </div>
        </div>
      </div>


      <Link className="text-decoration-none" to="/secretiveinstitute">
        <section className="hero_div2 text-white text-center mt-5 d-flex justify-content-center align-items-center">
          <div>
            <h1 className="text-white p-0 m-0 d-flex justify-content-center BackgroundSlideshow_h1_4">Become 0.00003% of the world's population<br className="d-block d-md-none" /></h1>
            <h1 className="text-white p-0 m-0 mt-4 d-flex justify-content-center BackgroundSlideshow_h1">THE WORLD’s MOST</h1>
            <div className="mt-1">
              <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_h1_5 text-center">Secretive Institute!</h1>
            </div>
            <h1 className="text-white pt-4 m-0 background_slideshow_h1_23 text-center pe-3" style={{ fontStyle: "italic", fontFamily: 'Times New Roman', fontSize: "35px" }}>"Do you even belong here? This isn't just another website, nor is its intent ordinary."</h1>
          </div>
        </section>
      </Link>

      

      <Link className="text-decoration-none" to="/cultperiod">
        <section className="hero_div22 text-white text-center mt-5 d-flex justify-content-center align-items-center">
          <div>
            <h1 className="text-white p-0 m-0 mt-5 d-flex justify-content-center BackgroundSlideshow_h1 who_do_h1">Who Do You Think We Are?</h1>
          </div>
        </section>
      </Link>

      <Link className="text-decoration-none" to="/institueOfOppurtunity">
        <section className="hero_div1 text-white text-center mt-5 d-flex justify-content-center align-items-center">
          <div>
            <div className="" style={{ marginTop: "-2%" }}>
              <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_h1_5 text-center">WELCOME NOTE</h1>
            </div>
          </div>
        </section>
      </Link>

    </>
  );
};

export default BackgroundSlideshow;
