import React from "react";

const SyndicatedCurriculum = () => {
  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              SYNDICATED CURRICULUM
            </h1>

            <p className="feature_homepage_p pt-4">
              Unlike the curriculum of any institution in the world, ours is
              both a mystery and as secretive as it can get. It’s tough,
              diverse, and versatile with laser sharp focus on the specifics of
              its learning outcomes. To get a glimpse.
            </p>
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              THE BILLIONAIRES SUITES
            </h1>

            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              ABOUT THE CURRICULUM
            </h2>

            <p className="feature_homepage_p pt-4">
              Unapologetic as we are for an intriguing and mysterious start,
              this curriculum comprises 30 enigmatic sections known as THE
              SUITES—each bearing the name of a billionaire who has left an
              indelible mark on the world. Here’s where most go astray: Do not
              be fooled into thinking that these suites are mere studies of how
              these figures amassed their fortunes. No, the essence of this
              curriculum lies in the shadowy depths between the lines, in the
              silent lessons not overtly spoken. For if becoming a billionaire
              was as simple as learning from another's playbook, the world would
              churn out a new billionaire every hour. Instead, we plunge you
              into a labyrinth where conventional wisdom falters, where the
              familiar becomes unfamiliar, and where the only way out is through
              an evolved mind.
              <br />
              <br />
              <strong>The Mysterious Pathways</strong>
              <br />
              This curriculum is not for the faint-hearted; it is a rite of
              passage, an initiation into a world where only the most adaptable
              survive. Each suite is a gateway, a portal that challenges you to
              unlearn, to question, to rebuild. The suites do not merely
              teach—they transform.
              <br />
              <br />
              <strong>The Covalent Bond: Institutes and Suites</strong>
              <br />
              These suites are intricately linked to our network of institutes,
              each one a powerful pillar supporting your ascension:
              <ul>
                <li>
                  <span className="text-black fw-bold">
                    Institute of Billionaires (IoB):
                  </span>
                  The heart of the ecosystem, where raw ambition is refined into
                  indomitable power.
                </li>
                <li>
                  <span className="text-black fw-bold">
                    Institute of Public Companies:
                  </span>
                  Where the public and private collide, creating new titans of
                  industry.
                </li>
                <li>
                  <span className="text-black fw-bold"> Institute of Opportunity:</span>
                  A cult-like crucible for those who dare to transcend
                  mediocrity.
                </li>
                <li>
                  <span className="text-black fw-bold">Institute of BillionHeirs:</span>
                  Preparing the next generation to inherit not just wealth, but
                  the mentality of empire.
                </li>
                <li>
                  <span className="text-black fw-bold">
                    Cartel & Syndicate Business School:
                  </span>
                  Where the rules are forged in iron, and the only path to
                  success is through collective strength.
                </li>
                <li>
                  <span className="text-black fw-bold">
                    Aristocracy, Business & Command School:
                  </span>
                  Teaching the art of command with the grace of aristocracy,
                  where power and influence converge.
                </li>
                <li>
                  <span className="text-black fw-bold">
                    Command & Staff College of Billions Administration:
                  </span>
                  A war room for future billionaires, where strategic brilliance
                  is honed to perfection.
                </li>
                <li>
                  <span className="text-black fw-bold"> Supply Chain Institute:</span>
                  The silent engine behind every empire, ensuring dominance at
                  every link of the chain.
                </li>
              </ul>
              <br />
              <strong>The Unwritten Rules</strong>
              <br />
              This is where we diverge from traditional academia. There are no
              books, no manuals—only the raw, unfiltered truth of what it takes
              to not just survive, but to dominate in a world where the weak are
              devoured. Its hardcore, raw training.
              <br />
              <br />
              In this covert curriculum, we will not merely hand you the keys to
              success; we will reforge your very being so that you are capable
              of unlocking doors that others never knew existed.
              <br />
              <br />
              <strong>The Covenant</strong>
              <br />
              Understand this: The Billionaires Suites are not just lessons—they
              are covenants, binding agreements that will either elevate you to
              unimagined heights or break you under their weight. Each suite is
              a trial, a test of your ability to see what others miss, to hear
              the whispers of opportunity in the cacophony of failure.
            </p>

            <hr className="mt-3 mb-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyndicatedCurriculum;
