import React from "react";
import { Link } from "react-router-dom";

const MainFounder = () => {
  return (
    <div>
      <Link className="text-decoration-none" to="/scholarshiphomepage">
        <section className="hero_div22 text-white text-center mt-5 d-flex justify-content-center align-items-center">
          <div>
            <h1 className="text-white p-0 m-0 mt-5 d-flex justify-content-center BackgroundSlideshow_h1 who_do_h1 text-uppercase">
              scholarship portal
            </h1>
          </div>
        </section>
      </Link>
    </div>
  );
};

export default MainFounder;
