import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./layout/HomePage";
import Contact from "./pages/contact/Contact";
import List from "./pages/List";
import DetailPage from "./components/BoxPage/DetailPage";
import TrainingCountryPage from "./components/BoxPage/TraningCountryPage";
import GraduatingCountryPage from "./components/BoxPage/GraduatingCountryPage";
import ApprenticeshipCountryPage from "./components/BoxPage/GraduatingCountryPage";
import CompanyCountryPage from "./components/BoxPage/GraduatingCountryPage";
import SecretCountryPage from "./components/BoxPage/GraduatingCountryPage";
import UnicorningCountryPage from "./components/BoxPage/GraduatingCountryPage";
import ScholarshipHomePage from "./components/allfounder/ScholarshipHomePage";
import PublicCompaniesCommandOffice from "./pages/featuredprograms/PublicCompaniesCommandOffice";
import ScholarshipCountryPage from "./components/allfounder/ScholarshipCountryPage";
import GuaranteeCompany from "./pages/guaranteecompany/GuaranteeCompany";
import InstituteofPublicCompanies from "./pages/featuredprograms/InstituteofPublicCompanies";
import FreelancerVisaTrainingProgram from "./pages/featuredprograms/FreelancerVisaTrainingProgram";
import UnicornsUnited from "./pages/featuredprograms/UnicornsUnited";
import VenusSyndicate from "./pages/featuredprograms/VenusSyndicate";
import IobChamberCommerceIndustry from "./pages/featuredprograms/IobChamberCommerceIndustry";
import UnicornStockExchange from "./pages/featuredprograms/UnicornStockExchange";
import ScrollToTop from "./constant/ScrolltoTop";
import UnicornChamber from "./pages/chambercommerce/UnicornChamber";
import BillionairesChamber from "./pages/chambercommerce/BillionairesChamber";
import ConciergeHomepage from "./pages/conciergehomepage/ConciergeHomepage";
import ProfileHomepage from "./pages/profile/ProfileHomepage";
import PortalDetails from "./pages/portalsprogram/PortalDetails";
import EcommerceCountryPage from "./pages/portalsprogram/EcommerceCountryPage";
import InvestmentCountryPage from "./pages/portalsprogram/investmentCountryPage";
import AddvertisingCountryPage from "./pages/portalsprogram/AddvertisingCountryPage";
import AdmissionsCountryPage from "./pages/portalsprogram/AdmissionsCountryPage";
import IntituteOfOppurtunity from "./components/BackgroundSlideshow/IntituteOfOppurtunity";
import SecretiveInstitute from "./components/BackgroundSlideshow/SecretiveInstitute";
import Signup from "./forms/Signup";
import Login from "./forms/Login";
import Admissions from "./pages/navbarpages/Admissions";
import BillionairesUniversities from "./pages/navbarpages/BillionairesUniversities";
import OurCompanies from "./pages/navbarpages/OurCompanies";
import OurInstitution from "./pages/navbarpages/OurInstitution";
import OurPrograms from "./pages/navbarpages/OurPrograms";
import SyndicatedCurriculum from "./pages/navbarpages/SyndicatedCurriculum";
import InstituteOfOpportunity from "./pages/navbarpages/InstituteOfOpportunity";
import InstituteOfPublic from "./pages/navbarpages/InstituteOfPublic";
import InstituteOfBillionheirs from "./pages/navbarpages/InstituteOfBillionheirs";
import AristocracyBusinessSchool from "./pages/navbarpages/AristocracyBusinessSchool";
import SupplyChainInstitute from "./pages/navbarpages/SupplyChainInstitute";
import FoundingFathersInstitute from "./pages/navbarpages/FoundingFathersInstitute";
import EnvironmentProtectionInstitute from "./pages/navbarpages/EnvironmentProtectionInstitute";
import CultPeroid from "./pages/CultPeroid";
import EconomicEngineering from "./pages/navbarpages/EconomicEngineering";
import FamilyTraining from "./pages/navbarpages/FamilyTraining";
import RevenueEnhancement from "./pages/navbarpages/RevenueEnhancement";
import DebtElimination from "./pages/navbarpages/DebtElimination"
import InstituteForm from "./forms/InstituteForm";
import CartelSyndicatedBusiness from "./pages/navbarpages/CartelSyndicatedBusiness";
import InstituteofMillionaires from "./pages/navbarpages/InstituteofMillionaires";
import YvesSaint from "./pages/navbarpages/IOM/YvesSaint";
import LeonardoDa from "./pages/navbarpages/IOM/LeonardoDa";
import OprahWinfrey from "./pages/navbarpages/IOM/OprahWinfrey";
import SahibzadaYaqub from "./pages/navbarpages/IOM/SahibzadaYaqub";
import LinusPauling from "./pages/navbarpages/IOM/LinusPauling";
import CesarRitz from "./pages/navbarpages/IOM/CesarRitz";
import ArtificialIntelligence from "./pages/navbarpages/IOM/ArtificialIntelligence";
import GianniAngelli from "./pages/navbarpages/IOM/GianniAngelli";
import GatesBuffett from "./pages/navbarpages/IOM/GatesBuffett";

const router = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* HOME PAGE SECTION START */}
        <Route path="/" element={<HomePage />} />
        {/* HOME PAGE SECTION END */}

        {/* HERO SECTION START */}
        <Route path="/institueOfOppurtunity" element={<IntituteOfOppurtunity />} />
        <Route path="/secretiveinstitute" element={<SecretiveInstitute />} />
        <Route path="/cultperiod" element={<CultPeroid />} />
        {/* HERO SECTION END */}

        {/* FEATURED ROUTES START */}
        <Route path="/guaranteecompany" element={<GuaranteeCompany />} />
        <Route path="/public-companies-command-office" element={<PublicCompaniesCommandOffice />} />
        <Route path="/institute-of-public-companies" element={<InstituteofPublicCompanies />} />
        <Route path="/unicorn-stock-exchange" element={<UnicornStockExchange />} />
        <Route path="/freelancer-visa-training-program" element={<FreelancerVisaTrainingProgram />} />
        <Route path="/unicorn-united" element={<UnicornsUnited />} />
        <Route path="/venus-syndicate" element={<VenusSyndicate />} />
        {/* FEATURED ROUTES END */}

        {/* CHAMBERS ROUTES START */}
        <Route path="/unicorn-chamber-Commerce" element={<UnicornChamber />} />
        <Route path="/billionaires-chamber-Commerce" element={<BillionairesChamber />} />
        {/* CHAMBERS ROUTES END */}

        {/* PORTALS ROUTES START */}
        <Route path="/:type" element={<PortalDetails />} />
        <Route path="/investment-portal/:countryName" element={<InvestmentCountryPage /> } />
        <Route path="/addvertising-portal/:countryName" element={<AddvertisingCountryPage />} />
        <Route path="/admissions-portal/:countryName" element={<AdmissionsCountryPage />} />
        <Route path="/ecommerce-portal/:countryName" element={<EcommerceCountryPage />} />
        {/* PORTALS ROUTES END */}

        {/* CONCIERGE ROUTES START */}
        <Route path="/concierge-homepage" element={<ConciergeHomepage />} />
        {/* CONCIERGE ROUTES END */}

        {/* PROFILE ROUTES START */}
        <Route path="/profile" element={<ProfileHomepage />} />
        {/* PROFILE ROUTES END */}

        {/* PROGRAMS ROUTES START */}
        <Route path="/detail/:type" element={<DetailPage />} />
        {/* PROGRAMS ROUTES END */}
        
        {/* NAVBAR ROUTES START */}
        <Route path="/ourprograms" element={<OurPrograms />} />
        <Route path="/schoolAdmission" element={<Admissions />} />
        <Route path="/instituteofmillionaires" element={<InstituteofMillionaires />} />
                       <Route path="/yvessaint" element={<YvesSaint />} /> 
                       <Route path="/LeonardoDa" element={<LeonardoDa />} /> 
                       <Route path="/OprahWinfrey" element={<OprahWinfrey />} /> 
                       <Route path="/SahibzadaYaqub" element={<SahibzadaYaqub />} /> 
                       <Route path="/LinusPauling" element={<LinusPauling />} /> 
                       <Route path="/CesarRitz" element={<CesarRitz />} /> 
                       <Route path="/ArtificialIntelligence" element={<ArtificialIntelligence />} /> 
                       <Route path="/GianniAngelli" element={<GianniAngelli />} /> 
                       <Route path="/GatesBuffett" element={<GatesBuffett />} /> 
        <Route path="/instituteofopportunity" element={<InstituteOfOpportunity />} />
        <Route path="/instituteofopublic" element={<InstituteOfPublic />} />
        <Route path="/instituteofobillionheirs" element={<InstituteOfBillionheirs />} />
        <Route path="/cartel-syndicated-business-school" element={<CartelSyndicatedBusiness />} />
        <Route path="/aristocracybusinessschool" element={<AristocracyBusinessSchool />} />
        <Route path="/supplychaininstitute" element={<SupplyChainInstitute />} />
        <Route path="/foundingfatherinstitute" element={<FoundingFathersInstitute />} />
        <Route path="/environmentprotectioninstitute" element={<EnvironmentProtectionInstitute />} /> 
        <Route path="/syndicatedcurriculum" element={<SyndicatedCurriculum />} />
        <Route path="/ourcompanies" element={<OurCompanies />} />
        <Route path="/ourinstitution" element={<OurInstitution />} />
        <Route path="/billionairesuniversities" element={<BillionairesUniversities />} />
        <Route path="/economicengineering" element={<EconomicEngineering />} />
        <Route path="/familytraining" element={<FamilyTraining />} />
        <Route path="/revenueenhancement" element={<RevenueEnhancement />} />
        <Route path="/debtelimination " element={<DebtElimination />} />
        {/* NAVBAR ROUTES END */}

        {/* PROGRAMS ROUTES START */}
        <Route path="/hungering/:countryName" element={<TrainingCountryPage />} />
        <Route path="/planing/:countryName" element={<GraduatingCountryPage />} />
        <Route path="/unicorning/:countryName" element={<ApprenticeshipCountryPage />} />
        <Route path="/billioaireing/:countryName" element={<CompanyCountryPage />} />
        <Route path="/warning/:countryName" element={<SecretCountryPage />} />
        <Route path="/teching/:countryName" element={<UnicorningCountryPage />} />
        {/* PROGRAMS ROUTES END */}
        
        {/* HEADER ROUTES START */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration-form" element={<InstituteForm />} />
        {/* HEADER ROUTES END */}

        {/* SCHOLARSHIP ROUTES START */}
        <Route path="/scholarshiphomepage" element={<ScholarshipHomePage />} />
        <Route path="/scholarshipcountrypage/:countryName" element={<ScholarshipCountryPage />} />
        {/* SCHOLARSHIP ROUTES END */}




        <Route
          path="/iob-chamber-commerce-industry"
          element={<IobChamberCommerceIndustry />}
        />
        <Route path="/list" element={<List />} />
        
      </Routes>
    </>
  );
};

export default router;
