import React from "react";
import { useParams } from "react-router-dom";
import ProfileImg from "../../assets/images/hungreing_homepage.jpg";
import SecondHeading from "../textcomponents/SecondHeading";
import FormButton from "../textcomponents/FormButton";

function DetailPage() {
  const { type } = useParams();

  return (
    <div className="detail-page">
      <div className="programs_homepage_back">
        <div className="w-100">
          <div className="programs_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {type === "hungering" && (
        <>
          <div className="container mt-5 mb-5">
            <div className="row g-0 mt-5">
              <div className="">
                <SecondHeading count="01" title="hunger-ing" />
              </div>
            </div>
            <div className="row g-1 mt-4">
              <div className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                <div className="pe-2 pe-lg-5">
                  <p className="feature_homepage_p">
                    HUNGER IS GOOD, and Hunger-ing is that spirit of hunger, a
                    relentless drive that fuels the most successful leaders in
                    the world. It’s not just a desire for success—it’s an
                    insatiable appetite that pushes you beyond your limits,
                    compelling you to achieve what others only dream of. Hunger
                    is what drove our ancestors towards survival, and we the
                    eight billion, are only carrying forward that desire to
                    survive, transforming thus into prodigies of the hunger
                    driven. <br />
                    <br />
                    In this pillar of our curriculum, you’ll learn how to
                    cultivate and harness this deep-seated hunger, transforming
                    it into a powerful force that drives every decision, every
                    action, and every outcome. Hunger-ing is about never being
                    satisfied with the status quo, always pushing for more, and
                    turning every setback into a stepping stone toward greater
                    achievements. This is where the fire of ambition is ignited,
                    and where you’ll learn the strategies to keep that fire
                    burning, no matter how challenging the journey becomes.
                    Hunger-ing is not just a trait—it’s a lifestyle, a
                    commitment to excellence that sets the stage for everything
                    else you’ll accomplish.
                  </p>

                  <div className="mt-4">
                    <FormButton name="Register Now" url="/registration-form" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 profile_div1 d-flex align-items-center p-3">
                <img
                  src={ProfileImg}
                  alt="JasonOppenheim"
                  className="img-fluid rounded-2"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {type === "planing" && (
        <>
          <div className="container mt-5 mb-5">
            <div className="row g-0 mt-5">
              <div className="">
                <SecondHeading count="01" title="No Plan B-ing" />
              </div>
            </div>
            <div className="row g-1 mt-4">
              <div className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                <div className="pe-2 pe-lg-5">
                  <p className="feature_homepage_p">
                    No Plan B-ing is the philosophy that separates the truly
                    committed from the merely interested. In this pillar, you’ll
                    dive deep into the mindset of those who have no fallback
                    options, who burn the boats and move forward with unwavering
                    focus. This is about total commitment to your goals, with no
                    safety nets or escape routes. It’s about embracing the idea
                    that success is not just a possibility—it’s the only option.
                    <br />
                    <br />
                    You’ll learn how to eliminate distractions, overcome doubts,
                    and develop a laser-like focus on your vision. No Plan B-ing
                    teaches you to operate with a level of intensity and purpose
                    that ensures you leave nothing on the table. This approach
                    not only drives you to reach your goals but also prepares
                    you to face and conquer any challenge that comes your way,
                    knowing that failure is not an option.
                  </p>
                  <div className="mt-4">
                    <FormButton name="Register Now" url="/registration-form" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 profile_div1 d-flex align-items-center p-3">
                <img
                  src={ProfileImg}
                  alt="JasonOppenheim"
                  className="img-fluid rounded-2"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {type === "unicorning" && (
        <>
          <div className="container mt-5 mb-5">
            <div className="row g-0 mt-5">
              <div className="">
                <SecondHeading count="01" title="unicorn-ing" />
              </div>
            </div>
            <div className="row g-1 mt-4">
              <div className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                <div className="pe-2 pe-lg-5">
                  <p className="feature_homepage_p">
                    Unicorn-ing is the process of transforming ambitious ideas
                    into billion-dollar realities. This pillar of the curriculum
                    is designed to teach you the strategies and mindset required
                    to create, scale, and sustain a unicorn—a company valued at
                    over $1 billion. <br />
                    <br />
                    You’ll explore the key components of building a business
                    that disrupts markets, captures massive value, and sets new
                    standards in the industry. Unicorn-ing is about more than
                    just growth; it’s about exponential growth, where every
                    decision is a strategic move towards dominating your market.
                    You’ll learn how to identify and capitalize on
                    opportunities, navigate the challenges of rapid expansion,
                    and position your company as a leader in its field.
                    Unicorn-ing is not just about reaching a billion-dollar
                    valuation—it’s about creating a lasting legacy that defines
                    your place in the world of business.
                  </p>
                  <div className="mt-4">
                    <FormButton name="Register Now" url="/registration-form" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 profile_div1 d-flex align-items-center p-3">
                <img
                  src={ProfileImg}
                  alt="JasonOppenheim"
                  className="img-fluid rounded-2"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {type === "billioaireing" && (
        <>
          <div className="container mt-5 mb-5">
            <div className="row g-0 mt-5">
              <div className="">
                <SecondHeading count="01" title="billioaire-ing" />
              </div>
            </div>
            <div className="row g-1 mt-4">
              <div className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                <div className="pe-2 pe-lg-5">
                  <p className="feature_homepage_p">
                    Billionaire-ing goes beyond achieving unicorn status; it’s
                    about building and sustaining billion-dollar empires. This
                    pillar focuses on the long-term strategies required to
                    maintain and grow your wealth, influence, and legacy. You’ll
                    delve into the art of managing large-scale enterprises,
                    understanding global markets, and making decisions that
                    drive continued success. Billionaire-ing is about creating a
                    lasting impact, not just for yourself, but for future
                    generations.
                    <br />
                    <br />
                    It’s about using your wealth and power responsibly,
                    leveraging them to create opportunities, solve problems, and
                    drive global prosperity. In this section, you’ll learn from
                    the experiences of the world’s most successful billionaires,
                    gaining insights into how they think, operate, and build
                    empires that stand the test of time. Billionaire-ing is the
                    ultimate goal—where success is not just achieved but
                    sustained and expanded.
                  </p>
                  <div className="mt-4">
                    <FormButton name="Register Now" url="/registration-form" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 profile_div1 d-flex align-items-center p-3">
                <img
                  src={ProfileImg}
                  alt="JasonOppenheim"
                  className="img-fluid rounded-2"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {type === "warning" && (
        <>
          <div className="container mt-5 mb-5">
            <div className="row g-0 mt-5">
              <div className="">
                <SecondHeading count="01" title="War-ing" />
              </div>
            </div>
            <div className="row g-1 mt-4">
              <div className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                <div className="pe-2 pe-lg-5">
                  <p className="feature_homepage_p">
                    War-ing is the strategic art of outmaneuvering, outthinking,
                    and overpowering your competition. In the world of business,
                    success is often a battle, and this pillar prepares you for
                    that fight. You’ll learn how to approach business challenges
                    as a strategist, understanding the dynamics of competition,
                    conflict, and market domination. War-ing teaches you the
                    principles of military strategy applied to the corporate
                    world—how to anticipate moves, deploy resources effectively,
                    and strike with precision.
                    <br />
                    <br />
                    You’ll study historical battles and corporate warfare,
                    extracting lessons that you can apply to your own business
                    ventures. War-ing is about being prepared for every
                    scenario, understanding your strengths and weaknesses, and
                    knowing how to turn challenges into opportunities. It’s the
                    mindset of a warrior, ready to fight for success and emerge
                    victorious in the competitive world of business.
                  </p>
                  <div className="mt-4">
                    <FormButton name="Register Now" url="/registration-form" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 profile_div1 d-flex align-items-center p-3">
                <img
                  src={ProfileImg}
                  alt="JasonOppenheim"
                  className="img-fluid rounded-2"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {type === "teching" && (
        <>
          <div className="container mt-5 mb-5">
            <div className="row g-0 mt-5">
              <div className="">
                <SecondHeading count="01" title="Fin-Teching" />
              </div>
            </div>
            <div className="row g-1 mt-4">
              <div className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                <p className="feature_homepage_p">
                  Fin-Teching is where finance meets technology, creating the
                  future of business. This pillar of the curriculum explores the
                  cutting-edge innovations that are transforming industries and
                  redefining the way we think about money, transactions, and
                  economic systems. You’ll dive into the world of financial
                  technology, learning about blockchain, digital currencies,
                  AI-driven financial models, and more.
                  <br />
                  <br />
                  Fin-Teching is about staying ahead of the curve, understanding
                  the technologies that are driving change, and leveraging them
                  to create new opportunities and solve complex problems. In
                  this section, you’ll explore how fintech is disrupting
                  traditional finance, creating new markets, and opening up
                  possibilities that were unimaginable just a few years ago.
                  Fin-Teching is the future of finance—where innovation,
                  technology, and business converge to create unprecedented
                  opportunities for growth and success.
                </p>
                <div className="mt-4">
                  <FormButton name="Register Now" url="/registration-form" />
                </div>
              </div>
              <div className="col-md-5 col-12 profile_div1 d-flex align-items-center p-3">
                <img
                  src={ProfileImg}
                  alt="JasonOppenheim"
                  className="profile-image"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DetailPage;
