import React from "react";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const CartelSyndicatedBusiness = () => {
  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div class="row">
          <div class="col-12">
            <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
            CARTEL & SYNDICATE BUSINESS SCHOOL ™
            </h1>
            <h2 class="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              Hunting as a Pack
            </h2>

            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
             <span className="fw-bold"> Welcome to The Cartel & Syndicate Business School</span> which has been
              established on a single, powerful principle: those who stick
              together, hunt together. In the world of high-stakes business, the
              time it takes to shape billion-dollar companies is greatly reduced
              when we operate in unison, as a cohesive, unstoppable force.
              <br />
              <br />
              This institution is not just a school; it’s a brotherhood and
              sisterhood where collective success is the only currency that
              matters. Here, the rules are set in iron—stringent, unwavering,
              and designed for collective survival. We believe that in unity
              lies strength, and in collaboration, there is power.
              <br />
              <br />
              At the Cartel & Syndicate Business School, we don’t just prepare
              you to succeed—we prepare you to thrive within a syndicate of
              like-minded leaders, all committed to the same goal: dominating
              the market and creating empires. This is a place where the bonds
              you forge are as strong as the strategies you learn. Together, we
              hunt, we conquer, and we rise.
              <br />
              <br />
              Under the guidance of The Ultra Godfather, every member of this
              syndicate is trained in the skills unknown to other businesses
              with strategic brilliance necessary to move as one powerful
              entity. Here, there are no lone wolves—only a pack united by a
              common mission: to rule the business world with absolute
              authority.
              <br />
              <br />
              Welcome to the Cartel & Syndicate Business School, where the
              journey to billion-dollar success is not a solitary path, but a
              collective march toward greatness. Together, we set the standard,
              rewrite the rules, and redefine what it means to be truly
              unstoppable.
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr class="mt-3 mb-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartelSyndicatedBusiness;
