import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const AristocracyBusinessSchool = () => {
  const accordionData = [
    {
      question: "What is the Aristocracy, Business & Command School?",
      answer:
        "The Aristocracy, Business & Command School is an elite institution where the principles of aristocracy are integrated with modern business strategies and the art of command. It is designed to forge leaders who can wield power with authority and finesse.",
    },
    {
      question:
        "Who should apply to the Aristocracy, Business & Command School?",
      answer:
        "This school is for those who understand that leadership is not just about authority but about commanding respect through a blend of nobility, strategic insight, and disciplined decision-making. It is ideal for individuals who aspire to lead with both power and grace.",
    },
    {
      question:
        "What makes this school different from other leadership programs?",
      answer:
        "The Aristocracy, Business & Command School uniquely combines the timeless principles of aristocracy with cutting-edge business acumen and command expertise. It teaches you not just to lead, but to rule with an air of unassailable confidence and strategic foresight.",
    },
    {
      question:
        "What will I learn at the Aristocracy, Business & Command School?",
      answer:
        "You will learn how to integrate the refinement of aristocracy into your leadership style, how to make strategic business decisions with long-term impact, and how to command respect and authority in any situation. The curriculum is designed to elevate you from a leader to a ruler.",
    },
    {
      question: "How does the school approach the concept of command?",
      answer:
        "Command at this school is not just about issuing orders; it’s about making decisions that resonate through generations. You will be trained to lead with authority, to negotiate with the finesse of a diplomat, and to dominate with the presence of a true aristocrat.",
    },
    {
      question: "What role does aristocracy play in the curriculum?",
      answer:
        "Aristocracy is at the core of the curriculum. You will learn how the principles of nobility—such as legacy, heritage, and the strategic use of influence—can be applied to modern business and leadership practices to achieve unparalleled success.",
    },
    {
      question:
        "What kind of leader does the Aristocracy, Business & Command School aim to produce?",
      answer:
        "The school aims to produce leaders who are not only strategic and powerful but also carry the grace and refinement of true aristocrats. Graduates are expected to lead with a blend of strength, wisdom, and elegance that commands respect and authority.",
    },
    {
      question: "How selective is the Aristocracy, Business & Command School?",
      answer:
        "The school is extremely selective, admitting only those who demonstrate the potential to lead with both power and grace. The admissions process is rigorous, designed to identify individuals who can uphold the traditions of aristocracy while excelling in modern business.",
    },
    {
      question: "What is the school’s philosophy on leadership?",
      answer:
        "The school’s philosophy is that true leadership is a balance of power and grace. It teaches that leaders must be both commanding and refined, capable of making decisions that will impact not just their immediate surroundings but the world at large.",
    },
    {
      question: "How do I apply to the Aristocracy, Business & Command School?",
      answer:
        "Admission is by invitation only. Prospective candidates who embody the qualities of both a strategic leader and a refined aristocrat may express their interest through our School of Admissions, where their suitability for this elite program will be thoroughly evaluated.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              ARISTOCRACY, BUSINESS & COMMAND SCHOOL ™
            </h1>

            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              Hunting as a Pack
            </h2>

            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $1,185
              <br />
              <strong>Duration:</strong> 2 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> Institute of Public Companies
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Aristocracy, Business & Command School, where the
              refinement of aristocracy meets the ruthless efficiency of modern
              business and the unyielding discipline of command. This is not
              just a school—it is the crucible where future leaders are forged,
              leaders who understand that true power lies at the intersection of
              nobility, strategy, and authority.
              <br /> <br />
              At this institution, we teach that aristocracy is more than a
              title—it’s a mindset, a strategic tool that, when combined with
              business acumen and command expertise, creates leaders capable of
              shaping industries, influencing markets, and commanding respect on
              the global stage. Here, the ancient traditions of nobility are
              seamlessly woven into the fabric of contemporary leadership,
              providing a unique advantage in a world that increasingly values
              heritage and legacy.
              <br /> <br />
              The Aristocracy, Business & Command School is where you learn to
              walk the fine line between power and influence with the grace of a
              born leader. It is where you master the art of decision-making,
              not just for today, but for generations to come. This school is
              built on the principle that true leaders are those who can command
              with authority, negotiate with finesse, and dominate with an air
              of unassailable confidence.
              <br /> <br />
              This is where the elite come to be molded, where the strategies of
              the aristocracy are not just taught but lived. Welcome to the
              Aristocracy, Business & Command School—where legacy, power, and
              command converge.
            </p>

            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr className="mt-3 mb-3" />

            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>

            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AristocracyBusinessSchool;
