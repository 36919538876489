// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { ChevronDown, AlignRight } from "lucide-react";
// import "./Navbar.css";

// const Navbar = () => {
//   const navigate = useNavigate();
//   const [activeDropdown, setActiveDropdown] = useState(null);
//   const [subActiveDropdown, setSubActiveDropdown] = useState(null);
//   const [subSubActiveDropdown, setSubSubActiveDropdown] = useState(null);
//   const [scrolled, setScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const isScrolled = window.scrollY > 50;
//       setScrolled(isScrolled);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const toggleDropdown = (index) => {
//     setActiveDropdown(activeDropdown === index ? null : index);
//     setSubActiveDropdown(null); // Reset sub-dropdown when main dropdown changes
//   };

//   const toggleSubDropdown = (index) => {
//     setSubActiveDropdown(subActiveDropdown === index ? null : index);
//     setSubSubActiveDropdown(null); // Reset sub-sub-dropdown when sub-dropdown changes
//   };

//   const toggleSubSubDropdown = (index) => {
//     setSubSubActiveDropdown(subSubActiveDropdown === index ? null : index);
//   };

//   const handleMouseLeave = () => {
//     setActiveDropdown(null);
//     setSubActiveDropdown(null);
//     setSubSubActiveDropdown(null);
//   };

//   const handleNavigation = (path) => {
//     navigate(`/${path}`);
//   };

//   const mainCards = [
//     { title: "HUNGER-ING", type: "hungering" },
//     { title: "NO PLAN B-ING", type: "planing" },
//     { title: "UNICORN-ING", type: "unicorning" },
//     { title: "BILLIONAIRE-ING", type: "billioaireing" },
//     { title: "WAR-ING", type: "warning" },
//     { title: "FIN TECH-ING", type: "teching" },
//   ];

//   return (
//     <div className="main_navbar m-0 p-0">
//       <nav
//         className={`navbar navbar-expand-xl sticky-top pb-2 pb-md-0 ${
//           scrolled ? "scrolled" : ""
//         }`}
//       >
//         <div className="container-fluid">
//           <Link className="navbar-brand" to="/">
//             <h4 className="text-white p-0 pt-2 pb-2 ps-0 ps-lg-0 m-0 navbar_h4">
//               INSTITUTE
//               <span style={{ fontStyle: "italic" }}>&nbsp;of&nbsp;</span>
//               BILLIONAIRES
//             </h4>
//           </Link>
//           <button
//             className="navbar-toggler border border-white bg-secondary"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <AlignRight color="white" />
//           </button>
//           <div className="collapse navbar-collapse" id="navbarNav">
//             <ul className="navbar-nav ms-auto">
//               <li className="nav-item">
//                 <Link className="nav-link d-flex align-items-center" to="/">
//                   HOME
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//               </li>
//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-programs"
//                   id="navbarDropdownPrograms"
//                   role="button"
//                   aria-expanded={activeDropdown === "system"}
//                   onClick={() => toggleDropdown("system")}
//                 >
//                   OUR ECO SYSTEM <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === "system" ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownPrograms"
//                 >
//                   <li>
//                     <Link className="dropdown-item" to="/schoolAdmission">
//                       School of Admissions
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/ourprograms">
//                       Our Programs, The MBA
//                     </Link>
//                   </li>
//                   <li className="dropdown-submenu">
//                     <Link
//                       className="dropdown-item d-flex align-items-end justify-content-between"
//                       onClick={(e) => {
//                         e.preventDefault();
//                         toggleSubDropdown("subInstitutions");
//                       }}
//                     >
//                       Our Companies <ChevronDown size={20} />
//                     </Link>
//                     <ul
//                       className={`dropdown-menu ${
//                         subActiveDropdown === "subInstitutions" ? "show" : ""
//                       }`}
//                       style={{ marginLeft: "100%", marginTop: "-10%" }}
//                     >
//                       <li>
//                         <Link className="dropdown-item" to="/guaranteecompany">
//                           Institute Of Billionaires Guarantee LTD.
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/unicorn-stock-exchange"
//                         >
//                           Unicorn Stock Exchange
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/unicorn-chamber-Commerce"
//                         >
//                           Unicorns Chamber of Commerce & Industry
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/billionaires-chamber-Commerce"
//                         >
//                           Billionaires Chamber of Commerce & Industry
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/">
//                           Do Intel
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/">
//                           HRD Authority
//                         </Link>
//                       </li>
//                     </ul>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/syndicatedcurriculum">
//                       Syndicated Curriculum
//                     </Link>
//                   </li>
//                 </ul>
//               </li>

//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-programs"
//                   role="button"
//                   aria-expanded={activeDropdown === "UNIVERSITY"}
//                   onClick={() => toggleDropdown("UNIVERSITY")}
//                 >
//                   BILLIONAIRES UNIVERSITY <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === "UNIVERSITY" ? "show" : ""
//                   }`}
//                 >
//                   {/* Our Institutions Dropdown */}
//                   <li className="dropdown-submenu">
//                     <Link
//                       className="dropdown-item d-flex align-items-end justify-content-between"
//                       onClick={(e) => {
//                         e.preventDefault();
//                         toggleSubDropdown("subInstitutions");
//                       }}
//                     >
//                       Our INSTITUTIONS <ChevronDown size={20} />
//                     </Link>
//                     <ul
//                       className={`dropdown-menu ${
//                         subActiveDropdown === "subInstitutions" ? "show" : ""
//                       }`}
//                       style={{ marginLeft: "100%", marginTop: "-10%" }}
//                     >
//                       {/* Institute of Millionaires Dropdown */}
//                       <li className="dropdown-submenu">
//                         <Link
//                           className="dropdown-item d-flex align-items-end justify-content-between"
//                           onClick={(e) => {
//                             e.preventDefault();
//                             toggleSubSubDropdown("subInstitutionsMillionaires");
//                           }}
//                         >
//                           Institute Of Millionaires <ChevronDown size={20} />
//                         </Link>
//                         <ul
//                           className={`dropdown-menu ${
//                             subSubActiveDropdown ===
//                             "subInstitutionsMillionaires"
//                               ? "show"
//                               : ""
//                           }`}
//                           style={{ marginLeft: "100%", marginTop: "-4%" }}
//                         >
//                           <li>
//                             <Link className="dropdown-item" to="/yvessaint">
//                               YVES SAINT LAURENT SCHOOL OF
//                               <br /> FASHION AND STYLE
//                             </Link>
//                           </li>
//                           <li>
//                             <Link className="dropdown-item" to="/LeonardoDa">
//                               LEONARDO DA VINCI SCHOOL OF
//                               <br /> ARTS CULTURE
//                             </Link>
//                           </li>
//                           <li>
//                             <Link className="dropdown-item" to="/OprahWinfrey">
//                               OPRAH WINFREY SCHOOL OF
//                               <br /> MEDIA PUBLIC RELATIONS
//                             </Link>
//                           </li>
//                           <li>
//                             <Link
//                               className="dropdown-item"
//                               to="/SahibzadaYaqub"
//                             >
//                               SAHIBZADA YAQUB KHAN SCHOOL OF
//                               <br />
//                               DIPLOMACY INTERNATIONAL RELATIONS
//                             </Link>
//                           </li>
//                           <li>
//                             <Link className="dropdown-item" to="/LinusPauling">
//                               LINUS PAULING SCHOOL OF NUTRITION
//                               <br />
//                               HEALTH, INSTITUTE OF BILLIONAIRES
//                             </Link>
//                           </li>
//                           <li>
//                             <Link className="dropdown-item" to="/CesarRitz">
//                               César Ritz Butlers School of
//                               <br /> the Institute of Billionaires
//                             </Link>
//                           </li>
//                           <li>
//                             <Link
//                               className="dropdown-item"
//                               to="/ArtificialIntelligence"
//                             >
//                               ARTIFICIAL INTELLIGENCE SCHOOL OF
//                               <br />
//                               STRATEGIC WARFARE BUSINESS TACTICS
//                             </Link>
//                           </li>
//                           <li>
//                             <Link className="dropdown-item" to="/GianniAngelli">
//                               GIANNI AGNELLI FINISHING SCHOOL OF
//                               <br />
//                               INSITUTE OF BILLIONAIRES
//                             </Link>
//                           </li>
//                           <li>
//                             <Link className="dropdown-item" to="/GatesBuffett">
//                               GATES-BUFFETT SCHOOL OF <br />
//                               PHILANTHROPY
//                             </Link>
//                           </li>
//                         </ul>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/instituteofopportunity"
//                         >
//                           Institute Of Opportunity
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/instituteofopublic"
//                         >
//                           Institute Of Public Companies
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/instituteofobillionheirs"
//                         >
//                           Institute Of Billionheirs
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/cartel-syndicated-business-school"
//                         >
//                           CARTEL & SYNDICATE BUSINESS SCHOOL
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/aristocracybusinessschool"
//                         >
//                           Aristocracy, Business & Command School
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/supplychaininstitute"
//                         >
//                           Supply Chain Institute
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/foundingfatherinstitute"
//                         >
//                           INSTITUTE OF THE FOUNDING FATHERS OF THE WORLD
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           className="dropdown-item"
//                           to="/environmentprotectioninstitute"
//                         >
//                           Institute of Environment Protection & Conservation
//                         </Link>
//                       </li>
//                     </ul>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-portal"
//                   id="navbarDropdownPortal"
//                   role="button"
//                   aria-expanded={activeDropdown === 2}
//                   onClick={() => toggleDropdown(2)}
//                 >
//                   PROGRAMS <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === 2 ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownPortal"
//                 >
//                   {mainCards.map((card, index) => (
//                     <li key={index}>
//                       <Link
//                         className="dropdown-item"
//                         to={`/detail/${card.type}`}
//                       >
//                         {card.title}
//                       </Link>
//                     </li>
//                   ))}
//                 </ul>
//               </li>
//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-programs"
//                   id="navbarDropdownPrograms"
//                   role="button"
//                   aria-expanded={activeDropdown === "technology"}
//                   onClick={() => toggleDropdown("technology")}
//                 >
//                   TECHNOLOGY <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === "technology" ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownPrograms"
//                 >
//                   <li>
//                     <Link className="dropdown-item">SAAS Projects</Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item">Software</Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item">AI University</Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-companies"
//                   id="navbarDropdownCompanies"
//                   role="button"
//                   aria-expanded={activeDropdown === 4}
//                   onClick={() => toggleDropdown(4)}
//                 >
//                   SERVICES CONSULTANCY <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === 4 ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownCompanies"
//                 >
//                   <li>
//                     <Link className="dropdown-item" to="/revenueenhancement">
//                       Revenue Enhancement
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/debtelimination">
//                       Debt Elimination
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/familytraining">
//                       Family Trainings
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/economicengineering">
//                       Economics Engineering
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-portals"
//                   id="navbarDropdownPortals"
//                   role="button"
//                   aria-expanded={activeDropdown === "portals"}
//                   onClick={() => toggleDropdown("portals")}
//                 >
//                   OUR PORTALS <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === "portals" ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownPortals"
//                 >
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       onClick={() => handleNavigation("investment-portal")}
//                     >
//                       Investment Portal
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       onClick={() => handleNavigation("advertising-portal")}
//                     >
//                       Advertising Portal
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       onClick={() => handleNavigation("admissions-portal")}
//                     >
//                       Admission Portal
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       onClick={() => handleNavigation("ecommerce-portal")}
//                     >
//                       Ecommerce Portal
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item">
//                 <Link
//                   className="nav-link d-flex align-items-center"
//                   to="/contact"
//                 >
//                   CONTACT
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//               </li>
//               <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#registration"
//                   id="navbarDropdownRegistration"
//                   role="button"
//                   aria-expanded={activeDropdown === "registration"}
//                   onClick={() => toggleDropdown("registration")}
//                 >
//                   REGISTRATION <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === "registration" ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownRegistration"
//                 >
//                   <li>
//                     <Link className="dropdown-item" to="/login">
//                       Login
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/signup">
//                       Sign Up
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// };

// export default Navbar;

// {
//   /* <li className="nav-item dropdown" onMouseLeave={handleMouseLeave}>
//                 <Link
//                   className="nav-link dropdown-toggle d-flex align-items-center"
//                   href="#our-programs"
//                   id="navbarDropdownPrograms"
//                   role="button"
//                   aria-expanded={activeDropdown === "institution"}
//                   onClick={() => toggleDropdown("institution")}
//                 >
//                   OUR INSTITUTION <ChevronDown size={16} />
//                   <div className="vr-line3 ms-2"></div>
//                 </Link>
//                 <ul
//                   className={`dropdown-menu ${
//                     activeDropdown === "institution" ? "show" : ""
//                   }`}
//                   aria-labelledby="navbarDropdownPrograms"
//                 >
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       to="/instituteofopportunity"
//                     >
//                       Institute Of Opportunity
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/instituteofopublic">
//                       Institute Of Public Companies
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       to="/instituteofobillionheirs"
//                     >
//                       Institute Of Billionheirs
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       to="/cartel-syndicated-business-school"
//                     >
//                      CARTEL & SYNDICATE BUSINESS SCHOOL
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       to="/aristocracybusinessschool"
//                     >
//                       Aristocracy, Business & Command School
//                     </Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/supplychaininstitute">
//                       Supply Chain Institute
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       to="/foundingfatherinstitute"
//                     >
//                       Institute of Founding Fathers
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       className="dropdown-item"
//                       to="/environmentprotectioninstitute"
//                     >
//                       Institute of Environment Protection & Conservation
//                     </Link>
//                   </li>
//                 </ul>
//               </li> */
// }

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronDown, AlignRight } from "lucide-react";
import "./Navbar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to toggle submenu visibility
  const toggleSubmenu = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the parent dropdown from closing
    setOpenSubmenu(!openSubmenu);
  };

  const mainCards = [
    { title: "HUNGER-ING", type: "hungering" },
    { title: "NO PLAN B-ING", type: "planing" },
    { title: "UNICORN-ING", type: "unicorning" },
    { title: "BILLIONAIRE-ING", type: "billioaireing" },
    { title: "WAR-ING", type: "warning" },
    { title: "FIN TECH-ING", type: "teching" },
  ];

  return (
    <div className="main_navbar m-0 p-0">
      <nav
        className={`navbar navbar-expand-xl sticky-top pb-2 pb-md-0 ${
          scrolled ? "scrolled" : ""
        }`}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <h4 className="text-white p-0 pt-2 pb-2 ps-0 ps-lg-0 m-0 navbar_h4">
              INSTITUTE
              <span style={{ fontStyle: "italic" }}>&nbsp;of&nbsp;</span>
              BILLIONAIRES
            </h4>
          </Link>
          <button
            className="navbar-toggler border border-white bg-secondary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <AlignRight color="white" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              {/* Home */}
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center" to="/">
                  HOME
                  <div className="vr-line3 ms-2"></div>
                </Link>
              </li>

              {/* Our Eco System Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownSystem"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  OUR ECO SYSTEM <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownSystem"
                >
                  <li>
                    <Link className="dropdown-item" to="/schoolAdmission">
                      School of Admissions
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ourprograms">
                      Our Programs, The MBA
                    </Link>
                  </li>

                  {/* OUR COMPANIES Submenu */}
                  <li className="dropdown-submenu">
                    <Link
                      className="dropdown-item dropdown-toggle"
                      to="#"
                      onClick={toggleSubmenu} // Call toggleSubmenu on click
                      id="navbarDropdownCOMPANIES"
                      role="button"
                    >
                      OUR COMPANIES
                    </Link>
                    <ul
                      className={`dropdown-menu companies_drop ${
                        openSubmenu ? "show" : ""
                      }`}
                      aria-labelledby="navbarDropdownCOMPANIES"
                      onClick={(e) => e.stopPropagation()} // Prevent submenu from closing on click
                    >
                      <li>
                        <Link className="dropdown-item" to="/guaranteecompany">
                          Institute Of Billionaires Guarantee LTD.
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/unicorn-stock-exchange"
                        >
                          Unicorn Stock Exchange
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/unicorn-chamber-Commerce"
                        >
                          Unicorns Chamber of Commerce <br/> & Industry
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/billionaires-chamber-Commerce"
                        >
                          Billionaires Chamber of Commerce <br/> & Industry
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/">
                          Do Intel
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/">
                          HRD Authority
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/syndicatedcurriculum">
                      Syndicated Curriculum
                    </Link>
                  </li>
                </ul>
              </li>
              {/* BILLIONAIRES UNIVERSITY Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownUniversity"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  BILLIONAIRES UNIVERSITY <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownUniversity"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/instituteofopportunity"
                    >
                      Institute Of Opportunity
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/instituteofopublic">
                      Institute Of Public Companies
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/instituteofobillionheirs"
                    >
                      Institute Of Billionheirs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/cartel-syndicated-business-school"
                    >
                      Cartel & Syndicate Business <br/> School
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/aristocracybusinessschool"
                    >
                      Aristocracy, Business & Command <br/> School
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/supplychaininstitute">
                      Supply Chain Institute
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/foundingfatherinstitute"
                    >
                      Institute of Founding Fathers
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/environmentprotectioninstitute"
                    >
                      Institute of Environment <br/> Protection & Conservation
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Our INSTITUTE OF MILLIONAIRES Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownMILLIONAIRES"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  INSTITUTE OF MILLIONAIRES <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMILLIONAIRES"
                >
                  <li>
                    <Link className="dropdown-item" to="/yvessaint">
                      YVES SAINT LAURENT SCHOOL OF <br /> FASHION AND STYLE
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/LeonardoDa">
                      LEONARDO DA VINCI SCHOOL OF <br /> ARTS CULTURE
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/OprahWinfrey">
                      OPRAH WINFREY SCHOOL OF
                      <br /> MEDIA PUBLIC RELATIONS
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/SahibzadaYaqub">
                      SAHIBZADA YAQUB KHAN SCHOOL OF
                      <br />
                      DIPLOMACY INTERNATIONAL RELATIONS
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/LinusPauling">
                      LINUS PAULING SCHOOL OF NUTRITION
                      <br />
                      HEALTH, INSTITUTE OF BILLIONAIRES
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/CesarRitz">
                      César Ritz Butlers School of
                      <br /> the Institute of Billionaires
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/ArtificialIntelligence"
                    >
                      ARTIFICIAL INTELLIGENCE SCHOOL OF
                      <br />
                      STRATEGIC WARFARE BUSINESS TACTICS
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/GianniAngelli">
                      GIANNI AGNELLI FINISHING SCHOOL OF
                      <br />
                      INSITUTE OF BILLIONAIRES
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/GatesBuffett">
                      GATES-BUFFETT SCHOOL OF <br />
                      PHILANTHROPY
                    </Link>
                  </li>
                </ul>
              </li>

              {/* PROGRAMS Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownPrograms"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  PROGRAMS <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownPrograms"
                >
                  {mainCards.map((card, index) => (
                    <li key={index}>
                      <Link
                        className="dropdown-item"
                        to={`/detail/${card.type}`}
                      >
                        {card.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              {/* TECHNOLOGY Dropdown */}
              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownTechnology"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  TECHNOLOGY
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownTechnology"
                >
                  <li>
                    <Link className="dropdown-item" to="/saasprojects">
                      SAAS Projects
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/software">
                      Software
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/aiuniversity">
                      AI University
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* SERVICES CONSULTANCY Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownConsultancy"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  SERVICES CONSULTANCY <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownConsultancy"
                >
                  <li>
                    <Link className="dropdown-item" to="/revenueenhancement">
                      Revenue Enhancement
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/debtelimination">
                      Debt Elimination
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/familytraining">
                      Family Trainings
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/economicengineering">
                      Economics Engineering
                    </Link>
                  </li>
                </ul>
              </li>

              {/* OUR PORTALS Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownPortals"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  OUR PORTALS <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownPortals"
                >
                  <li>
                    <Link className="dropdown-item" to="/investment-portal">
                      Investment Portal
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/advertising-portal">
                      Advertising Portal
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/admissions-portal">
                      Admission Portal
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ecommerce-portal">
                      Ecommerce Portal
                    </Link>
                  </li>
                </ul>
              </li>

              {/* CONTACT */}
              <li className="nav-item">
                <Link
                  className="nav-link d-flex align-items-center"
                  to="/contact"
                >
                  CONTACT
                  <div className="vr-line3 ms-2"></div>
                </Link>
              </li>

              {/* REGISTRATION Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdownRegistration"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  REGISTRATION <ChevronDown size={16} />
                  <div className="vr-line3 ms-2"></div>
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownRegistration"
                >
                  <li>
                    <Link className="dropdown-item" to="/login">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/signup">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
