import React from "react";
import Accordion from "../../constant/accordion";

const FamilyTraining = () => {
  const accordionData = [
    {
      question: "What is Billionaire Family Trainings?",
      answer:
        "Billionaire Family Trainings is an exclusive program designed to equip wealthy families with the knowledge and skills needed to maintain, grow, and pass on their wealth across generations.",
    },
    {
      question: "Who can benefit from Billionaire Family Trainings?",
      answer:
        "This service is ideal for families with significant wealth who wish to ensure that their legacy is preserved and expanded through strategic financial management and leadership development.",
    },
    {
      question: "What topics are covered in the training?",
      answer:
        "The training covers a wide range of topics, including financial management, investment strategies, leadership development, and the importance of family unity and shared vision in wealth preservation.",
    },
    {
      question: "How is the training customized for each family?",
      answer:
        "We tailor our programs to the specific needs and goals of each family, taking into account their unique dynamics, values, and aspirations. This ensures that the training is relevant and impactful for every family member.",
    },
    {
      question: "What is the role of family unity in the training?",
      answer:
        "Family unity is a core focus of the training. We teach families how to operate like a syndicate, making decisions collectively, leveraging each other’s strengths, and ensuring that the family’s interests are always aligned and protected.",
    },
    {
      question: "How does the training prepare young heirs for leadership?",
      answer:
        "Young heirs are taught the fundamentals of financial management, strategic thinking, and leadership skills needed to take on roles within the family’s business or wealth management structure, ensuring a smooth transition of power.",
    },
    {
      question: "Can the training help resolve family conflicts?",
      answer:
        "Yes, part of the training focuses on fostering communication and trust within the family, helping to resolve conflicts and ensure that the family operates as a cohesive unit.",
    },
    {
      question: "How does the training address succession planning?",
      answer:
        "Succession planning is a key component of the training. We work with families to develop strategies that ensure a smooth transition of leadership and wealth from one generation to the next.",
    },
    {
      question:
        "What are the long-term benefits of Billionaire Family Trainings?",
      answer:
        "The long-term benefits include sustained wealth across generations, strong family unity, and the ability to adapt and thrive in a changing world. Families are equipped to make decisions that will protect and grow their legacy for the future.",
    },
    {
      question: "How do we get started with Billionaire Family Trainings?",
      answer:
        "Families interested in Billionaire Family Trainings can contact our team for an initial consultation. We will assess your family’s needs and goals and develop a customized training program designed to secure and enhance your legacy.",
    },
  ];
  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Billionaire Family Trainings
            </h1>

            <p className="feature_homepage_p pt-4">
              At the highest echelons of wealth and power, success is not just
              an individual achievement—it’s a family legacy. Billionaire Family
              Trainings is an exclusive service designed to ensure that the
              torch of leadership, wealth, and influence is passed seamlessly
              from one generation to the next. This is where families are not
              just taught to maintain their wealth but to expand it, to lead
              with authority, and to preserve their legacy in a world that is
              constantly evolving.
              <br />
              <br />
              Our approach to family training is rooted in the understanding
              that every family is unique, with its own dynamics, values, and
              goals. We tailor our programs to address these specific needs,
              ensuring that each member of the family is prepared to contribute
              to the collective success. From teaching young heirs the
              fundamentals of financial management to instilling the strategic
              acumen needed to lead billion-dollar enterprises, our training
              covers every aspect of wealth stewardship.
              <br />
              <br />
              But this is more than just financial education. Billionaire Family
              Trainings focus on fostering a cartel-like unity within the
              family, where loyalty, trust, and shared vision are paramount. We
              teach families how to operate as a syndicate, making decisions
              together, leveraging each other’s strengths, and ensuring that the
              family’s interests are always protected and advanced.
              <br />
              <br />
              In a world where power dynamics are constantly shifting, the
              ability to maintain and grow a family’s wealth across generations
              is a rare and valuable skill. Billionaire Family Trainings is
              where the future leaders of your family are molded, ensuring that
              your legacy not only survives but thrives.
            </p>

            <hr className="mt-3 mb-3" />

            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyTraining;
