import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const InstituteOfPublic = () => {
  const accordionData = [
    {
      question: "What is the Institute of Public Companies?",
      answer:
        "The Institute of Public Companies is a premier institution designed to train and empower individuals to dominate the public markets. It is not just about taking companies public; it's about mastering the entire lifecycle of a public company and commanding the market like a syndicate.",
    },
    {
      question: "Who should apply to the Institute of Public Companies?",
      answer:
        "This institute is for those who are ready to lead and own billion-dollar enterprises, who have the ambition to control the narrative in the public markets, and who are prepared to operate with the mindset of a cartel—strategic, calculated, and relentless.",
    },
    {
      question:
        "What makes the Institute of Public Companies different from other business schools?",
      answer:
        "Unlike traditional business schools, the Institute of Public Companies teaches you how to manipulate market forces, leverage public capital, and maintain control over your enterprise in the public sphere. It’s about creating leaders who will dominate, not just participate, in the financial markets.",
    },
    {
      question: "What will I learn at the Institute of Public Companies?",
      answer:
        "You will learn the intricacies of IPOs, mergers, acquisitions, and market strategies. More importantly, you will learn how to operate with a syndicate mentality, ensuring that your company not only goes public but becomes a dominant force in its industry.",
    },
    {
      question: "How does the Institute approach the concept of leadership?",
      answer:
        "Leadership at the Institute is about control, influence, and power. We forge leaders who can navigate the public markets with precision, who can manipulate public opinion, and who are unafraid to make bold, decisive moves that others would shy away from.",
    },
    {
      question: "What kind of support can I expect during the program?",
      answer:
        "You will be mentored by seasoned experts who have themselves led public companies to extraordinary success. However, true to our ethos, you will be expected to demonstrate initiative and the ability to think like a syndicate leader—strategically and independently.",
    },
    {
      question: "What happens after I complete the program?",
      answer:
        "Graduates of the Institute of Public Companies will be handed over to lead a public enterprise with the knowledge and network needed to achieve total market dominance. You will leave the program ready to command a public company and drive it to billion-dollar success.",
    },
    {
      question: "How selective is the Institute of Public Companies?",
      answer:
        "Extremely selective. We only admit those who have the potential to become titans of industry. The admissions process is rigorous, designed to filter out anyone who lacks the vision, grit, and strategic mindset necessary to thrive in the public markets.",
    },
    {
      question: "What is the Institute’s philosophy on market competition?",
      answer:
        "Competition is viewed as a zero-sum game. At the Institute, you will learn that in the public markets, there is no room for second place. Our philosophy is one of total dominance—either you control the market, or you are controlled by it.",
    },
    {
      question: "How do I apply to the Institute of Public Companies?",
      answer:
        "Admission is by invitation only. If you believe you have what it takes to join this elite cartel of future market leaders, you may express your interest through our School of Admissions, where your potential will be thoroughly evaluated.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div class="row">
          <div class="col-12">
            <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              INSTITUTE OF PUBLIC COMPANIES
            </h1>

            <h2 class="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              The Brain Behind the IOB
            </h2>

            <p class="feature_homepage_p pt-4">
              <div class="p-3">
                <img
                  src="{IMG}"
                  alt="img"
                  class="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $1,185
              <br />
              <strong>Duration:</strong> 3 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> Institute of Public Companies
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Institute of Opportunity. Institute of Public
              Companies
              <br />
              <br />
              Welcome to the Institute of Public Companies, where the art of
              corporate dominance is refined and perfected. This is not just an
              institution—it’s the nerve center of a global cartel, a syndicate
              where the rules of public markets are rewritten to favor those who
              are bold enough to seize control. Here, we don’t just teach the
              mechanics of going public; we develop leaders who will command the
              stock exchanges, the market dynamics and forces, and build empires
              that will stand unchallenged, and crystal clear transactions. With
              AI & Technology interface, our Institute explores through the ups
              and downs of market manipulation as a beacon of fair business.{" "}
              <br />
              <br />
              At the Institute of Public Companies, we operate with a syndicate
              mentality. Our members are not mere students—they are the
              architects of tomorrow's billion-dollar enterprises, the power
              players who will dominate industries and set new benchmarks for
              success. Under the watchful protocols, from formation to
              execution, you will be initiated into the secrets of IPOs,
              mergers, and acquisitions, learning how to leverage public capital
              to its fullest potential while maintaining the iron grip of a
              private syndicate.
              <br />
              <br />
              If this is where billion-dollar companies are born, then the
              Institute of Public Companies is your Launchpad into a world where
              power is measured in billions and the stakes are nothing less than
              total market dominance. From the initial public offering to the
              intricate dance of market manipulation, you will master every
              aspect of the public company lifecycle. But understand this: we
              are not here to create followers; we are here to build leaders who
              will control the narrative, shape public opinion, and wield
              influence over the financial markets.
              <br />
              <br />
              Welcome to the cartel of success. Welcome to the Institute of
              Public Companies.
            </p>

            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr class="mt-3 mb-3" />

            <h1 class="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div class="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteOfPublic;
