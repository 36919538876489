import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BACK from "../../assets/images/university2.jpg";
import countries from "../../constant/countries";
import FirstHeading from "../textcomponents/FirstHeading";

const ScholarshipHomePage = () => {
  const [selectedLetter, setSelectedLetter] = useState("A");
  const navigate = useNavigate();

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.startsWith(selectedLetter)
  );

  const letters = Array.from(
    new Set(countries.map((country) => country.name.charAt(0)))
  ).sort();

  const handleCountryClick = (countryName) => {
    const country = countries.find((c) => c.name === countryName);
    if (country) {
      navigate(`/scholarshipcountrypage/${country.name}`, {
        state: {
          flag: country.flag,
          capital: country.capital,
          founder: country.founder,
          founderName: country.founderName,
        },
      });
    } else {
      console.error("Country not found:", countryName);
    }
  };

  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-12">
            <img
              width="100%"
              className="founder_mobile"
              src={BACK}
              alt="founderimg"
            />
          </div>
        </div>
      </div>
      <div className="container pb-5" style={{ marginTop: "-28%" }}>
        <div className="row">
          <div className="col-12 bg-white">
            <div className="ms-4 pt-5">
              <FirstHeading
                count="01"
                title="scholarship portals"
                tagline="Unlocking Global Leadership Opportunities"
              />
            </div>
            <p className="mt-4 feature_homepage_p ps-4 pe-4">
              In today’s rapidly evolving world, the need for visionary leaders
              and innovative entrepreneurs has never been more critical. The
              scholarship is dedicated to empowering young minds, equipping them
              with the skills, knowledge, and resources required to create
              billion-dollar companies that can shape the future. Through
              rigorous training at the Institute of Billionaires and the
              Institute of Public Companies, candidates are guided by industry
              experts, learning real-world strategies to navigate and succeed in
              the global business landscape. This scholarship offers not just
              financial support but a life-changing opportunity to build
              enterprises that can make a meaningful impact on both local and
              global economies. The selected scholars will gain unparalleled
              access to mentorship, cutting-edge resources, and a powerful
              network of global influencers, setting them on the path to
              becoming the leaders of the new civilization order.
            </p>
            <div className="container mt-5">
              <div>
                <FirstHeading
                  count="02"
                  title="Founders Nation Wise"
                  tagline="Find By Country Name"
                />
              </div>
              <div
                className="row g-0 mt-5 d-flex justify-content-center pt-2 pb-2"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <div className="d-flex flex-wrap ps-3">
                  {letters.map((letter) => (
                    <h6
                      key={letter}
                      className={`list_hover m-0 p-2 cursor-pointer ${
                        letter === selectedLetter ? "active" : ""
                      }`}
                      onClick={() => handleLetterClick(letter)}
                    >
                      {letter} |
                    </h6>
                  ))}
                </div>
              </div>
              <div>
                {selectedLetter && (
                  <>
                    <h6
                      className="mt-4 p-3 text-white"
                      style={{ backgroundColor: "var(--main-red-color)" }}
                    >
                      Countries starting with {selectedLetter}:
                    </h6>
                    <div className="row g-1">
                      {filteredCountries.map((country, index) => (
                        <div className="col-md-4 col-12" key={index}>
                          <p
                            className="country_name p-2 m-0 cursor-pointer"
                            onClick={() => handleCountryClick(country.name)}
                          >
                            {country.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScholarshipHomePage;
