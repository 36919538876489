import React from "react";
import Accordion from "../../../constant/accordion";

const SahibzadaYaqubKhan = () => {
  const accordionData = [
    {
      question:
        "What is the focus of the Sahibzada Yaqub Khan School of Diplomacy & International Relations?",
      answer:
        "The school focuses on teaching future billionaires the skills of diplomacy, international relations, and global negotiation, drawing on Sahibzada Yaqub Khan’s legacy of mediation and conflict resolution.",
    },
    {
      question: "Why is the school named after Sahibzada Yaqub Khan?",
      answer:
        "Sahibzada Yaqub Khan was a renowned diplomat and statesman who played key roles in global peace negotiations, making him an ideal figure to inspire students in diplomacy and international relations.",
    },
    {
      question: "What skills will students learn at this school?",
      answer:
        "Students will learn strategic diplomacy, conflict mediation, international negotiation, and cultural diplomacy, preparing them for leadership in global political and business arenas.",
    },
    {
      question:
        "How does this school fit into the curriculum of the Institute of Billionaires?",
      answer:
        "The school aligns with the Institute’s mission to train future billionaires by teaching them the skills necessary to navigate global political landscapes, form international alliances, and resolve conflicts.",
    },
    {
      question: "What role does diplomacy play in a billionaire’s success?",
      answer:
        "Diplomacy helps billionaires form strong international partnerships, manage political risks, and expand their businesses into new markets through strategic global relationships.",
    },
    {
      question: "Will students study real-world diplomatic events?",
      answer:
        "Yes, the curriculum includes case studies of major diplomatic negotiations, peace treaties, and global alliances, allowing students to learn from historical and contemporary examples.",
    },
    {
      question: "What is conflict mediation, and why is it important?",
      answer:
        "Conflict mediation involves resolving disputes between parties through negotiation and diplomacy. It is crucial for managing international business conflicts and maintaining global stability.",
    },
    {
      question: "Who will teach at the Sahibzada Yaqub Khan School?",
      answer:
        "The school will feature experts in international relations, global diplomacy, and conflict resolution, providing students with real-world insights and experience in global affairs.",
    },
    {
      question: "How does cultural diplomacy benefit business leaders?",
      answer:
        "Cultural diplomacy fosters mutual understanding between nations, which is essential for building trust, securing international deals, and expanding global influence.",
    },
    {
      question:
        "What is the ultimate goal of the Sahibzada Yaqub Khan School of Diplomacy & International Relations?",
      answer:
        "The goal is to equip future billionaires with the diplomatic skills and global awareness needed to lead their businesses internationally while contributing to global peace and cooperation.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Sahibzada Yaqub Khan School of Diplomacy & International Relations
            </h1>

            <p className="feature_homepage_p pt-4">
              The Sahibzada Yaqub Khan School of Diplomacy & International
              Relations at the Institute of Billionaires is named in honor of
              one of the most distinguished diplomats and statesmen of the 20th
              century. Sahibzada Yaqub Khan, a visionary diplomat and Pakistan’s
              former Foreign Minister, was renowned for his skillful
              negotiations and conflict resolution on the global stage. He
              played a pivotal role in mediating international conflicts,
              including efforts in the Middle East, South Asia, and the Cold
              War, making him a fitting figure to inspire future global leaders.
              <br />
              <br />
              At this school, future billionaires will learn the art of
              diplomacy, international relations, and global negotiation, skills
              that are essential for leading businesses in today’s
              interconnected world. Drawing from Khan’s legacy, the curriculum
              emphasizes strategic diplomacy, conflict mediation, and the
              ability to form cross-cultural alliances. The program is designed
              to train students to navigate complex political landscapes, foster
              international relationships, and handle delicate global
              negotiations with grace and poise.
              <br />
              <br />
              Students will study real-world diplomatic case studies, from peace
              treaties to trade negotiations, learning the importance of
              cultural understanding and geopolitical strategy. By mastering
              these skills, graduates of the Sahibzada Yaqub Khan School will be
              equipped to not only lead their businesses globally but also
              influence international policy and contribute to global peace and
              cooperation.
            </p>

            <hr className="mt-3 mb-3" />

            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SahibzadaYaqubKhan;
