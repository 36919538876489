import React from "react";
import Accordion from "../../constant/accordion";
import FormButton from "../../components/textcomponents/FormButton";
const InstituteofMillionaires = () => {
  const accordionData = [
    {
      question: "What is the Institute of Public Companies?",
      answer:
        "The Institute of Public Companies is a premier institution designed to train and empower individuals to dominate the public markets. It is not just about taking companies public; it's about mastering the entire lifecycle of a public company and commanding the market like a syndicate.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div class="row">
          <div class="col-12">
            <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              INSTITUTE OF MILLIONAIRES
            </h1>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr class="mt-3 mb-3" />

            <h1 class="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div class="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteofMillionaires
