import React from "react";
import Accordion from "../../../constant/accordion";

const GianniAngelli = () => {
  const accordionData = [
    {
      question: "What is the Gianni Agnelli Finishing School?",
      answer:
        "The Gianni Agnelli Finishing School is a part of the Institute of Billionaires, designed to teach future billionaires the art of refinement, elegance, and social diplomacy. Named after Gianni Agnelli, the school focuses on grooming students to carry themselves with poise and confidence in high society and global business circles.",
    },
    {
      question: "Who is Gianni Agnelli, and why is the school named after him?",
      answer:
        "Gianni Agnelli was a legendary Italian businessman and head of Fiat, known for his unparalleled style, grace, and influence in both business and high society. His ability to navigate complex social and business environments with charm and confidence makes him the perfect inspiration for a finishing school focused on elegance and leadership.",
    },
    {
      question:
        "What skills are taught at the Gianni Agnelli Finishing School?",
      answer:
        "Students learn critical social and cultural skills such as international etiquette, personal grooming, luxury brand knowledge, and how to project power and influence through personal style. The program also emphasizes the importance of navigating elite social and business environments with poise.",
    },
    {
      question: "Is this school only about fashion and style?",
      answer:
        "No, while personal style is a key component, the Gianni Agnelli Finishing School also focuses on cultural diplomacy, social grace, and the soft skills needed to succeed in global leadership roles. It trains students to handle high-pressure social and business situations with confidence and charm.",
    },
    {
      question:
        "How does the Gianni Agnelli Finishing School fit into the overall curriculum of the Institute of Billionaires?",
      answer:
        "The finishing school complements the broader curriculum of the Institute of Billionaires by ensuring that students not only excel in business strategy but also in personal refinement. It helps them become well-rounded leaders who can thrive in any social or professional environment.",
    },
    {
      question: "Is the finishing school aimed at specific industries?",
      answer:
        "The skills taught at the Gianni Agnelli Finishing School are universal and can be applied to any industry where social skills, diplomacy, and personal presentation play a key role in leadership and influence.",
    },
    {
      question:
        "How long is the program at the Gianni Agnelli Finishing School?",
      answer:
        "The program duration varies, as it is integrated into the broader curriculum of the Institute of Billionaires. It provides ongoing training as students progress through their leadership journey.",
    },
    {
      question:
        "What are the benefits of attending the Gianni Agnelli Finishing School?",
      answer:
        "Graduates leave the school with enhanced social skills, refined personal style, and the ability to navigate high-stakes environments with ease. These qualities help them excel as global leaders and ensure they stand out in both business and social settings.",
    },
    {
      question:
        "Why is elegance and style important for billion-dollar leaders?",
      answer:
        "Elegance and style are more than just appearances—they are tools for projecting confidence, authority, and influence. In high-level business and social environments, how you present yourself can open doors and create opportunities that strategy alone may not achieve.",
    },
    {
      question:
        "What kind of leaders does the Gianni Agnelli Finishing School aim to produce?",
      answer:
        "The school aims to produce leaders who not only excel in business but also in personal presentation, cultural diplomacy, and social influence. These leaders will command respect and attention in both the boardroom and the ballroom.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Gianni Agnelli Finishing School of the Institute of Billionaires
            </h1>

            <p className="feature_homepage_p pt-4">
              The Gianni Agnelli Finishing School at the Institute of
              Billionaires is named after one of the most influential and
              stylish business leaders of the 20th century, Gianni Agnelli, the
              iconic head of Fiat. Known for his refinement, elegance, and
              command in high society and global business circles, this school
              is designed to train future billionaires in the same spirit of
              sophistication and grace that Agnelli exemplified throughout his
              life.
              <br />
              <br />
              The school focuses on preparing students to not only lead
              billion-dollar companies but to navigate the complexities of high
              society and international diplomacy. Here, students learn critical
              social skills, cultural etiquette, and the importance of personal
              style and presentation in business and social settings. Whether
              negotiating with world leaders or attending elite events,
              graduates of the Gianni Agnelli Finishing School will stand out
              with their poise, confidence, and grace.
              <br />
              <br />
              Training at this finishing school goes beyond traditional business
              education. Students are taught the subtleties of social diplomacy,
              luxury brand knowledge, and how to carry themselves in a way that
              commands respect and attention. They also gain the ability to move
              seamlessly in high-stakes environments, embodying the elegance and
              charm that is essential for billion-dollar leaders in the modern
              world.
              <br />
              <br />
              By integrating these refined skills into the overall curriculum of
              the Institute of Billionaires, the Gianni Agnelli Finishing School
              ensures that its graduates are not only powerful business leaders
              but also symbols of sophistication and influence in the global
              arena.
            </p>
            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GianniAngelli;
